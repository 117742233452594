import { createTheme } from "@mui/material/styles";

export const tokens = () => ({
    primary: {
        100: '#4a6cd2',
        200: '#4367d3',
        300: '#3b62d4',
        400: '#355cd4',
        500: '#2f57d3',
        600: '#2c53d1',
        700: '#284fce',
        800: '#244ccb',
        900: '#2147c8'
    },
    black: {
        100: '#B8B8B8',
        200: '#A3A3A3',
        300: '#8F8F8F',
        400: '#7A7A7A',
        500: '#666666',
        600: '#4A4A4A',
        700: '#2E2E2E',
        800: '#121212',
        900: '#000000'
    },
    green: {
        100: '#c2e7d5',
        200: '#b0e4c8',
        300: '#9fe0bc',
        400: '#8cdeb0',
        500: '#7adba4',
        600: '#67d79a',
        700: '#51d590',
        800: '#3ad285',
        900: '#0C2D25'
    },
    secondary: {
        100: '#c2e7d5',
        200: '#b0e4c8',
        300: '#9fe0bc',
        400: '#8cdeb0',
        500: '#7adba4',
        600: '#67d79a',
        700: '#51d590',
        800: '#3ad285',
        900: '#0C2D25'
    },
    blue: {
        100: '#d3deff',
        200: '#becdff',
        300: '#a9bbff',
        400: '#95aaff',
        500: '#8197ff',
        600: '#6f84ff',
        700: '#5e70ff',
        800: '#5059ff',
        900: '#443dff'
    },
    red: {
        100: '#a06c66',
        200: '#a0635a',
        300: '#9f584e',
        400: '#9e4c43',
        500: '#9e4137',
        600: '#9d342c',
        700: '#9c261f',
        800: '#9b1510',
        900: '#900107'
    },
    white: {
        100: '#FFFFFF',
        200: '#FFFFFF',
        300: '#FFFFFF',
        400: '#FFFFFF',
        500: '#FFFFFF',
        600: '#E3E3E3',
        700: '#C7C7C7',
        800: '#ABABAB',
        900: '#8F8F8F'
    },
    gray: {
        100: '#D2D2D2',
        200: '#BDBDBD',
        300: '#A9A9A9',
        400: '#949494',
        500: '#808080',
        600: '#646464',
        700: '#484848',
        800: '#2C2C2C',
        900: '#101010'
    },
});

// theme settings
export const themeSettings = () => {
    const colors = tokens();
    return {
        palette: {
            primary: {
                main: colors.primary[500]
            },
            secondary: {
                main: colors.green[500]
            },
            neutral: {
                dark: colors.black[700],
                main: colors.black[500],
                light: colors.black[100]
            },
            background: {
                default: colors.white[500]
            }
        },
        typography: {
            fontFamily: [
                'Lato',
                'sans-serif',
            ].join(','),
            h1: {
                fontWeight: 700,
            },
            h2: {
                fontWeight: 700,
            },
            body1: {
                fontWeight: 400,
            },
            body2: {
                fontWeight: 400,
            },
            caption: {
                fontWeight: 300,
            },
        },
    }
}

export const theme = createTheme(themeSettings());

