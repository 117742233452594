export const CONSTANTS = {
    IDENTIFICATION_TYPES_EN: [
        {value: "NRIC", label: "Identification Card No."},
        {value: "PASSPORT", label: "Passport No."},
        {value: "ARMY", label: "Army No."},
        {value: "BRN", label: "Business Registration No."},
    ],
    IDENTIFICATION_TYPES_BM: [
        {value: "NRIC", label: "No. Kad Pengenalan"},
        {value: "PASSPORT", label: "No. Passport"},
        {value: "ARMY", label: "No. Tentera"},
        {value: "BRN", label: "Business Registration No."},
    ]
}
