import React, {FC} from "react";
import {Box, Grid, Typography} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";

interface IProps {
    supplier: any;
    buyer: any;
    isMobile: boolean;
}

const SupplierBuyer: FC<IProps> = (props: IProps) => {
    const {supplier, buyer, isMobile} = props;
    if (supplier === undefined && buyer === undefined)
        return null;

    const {
        supplier_tin, sst_reg_no, brn, msic_code
    } = supplier;
    const {
        buyer_name, buyer_tin,
        buyer_phone, buyer_sst_no
    } = buyer;

    return (
        <Box py={5}>
            <Grid container justifyContent="space-between">
                <Grid2 xs={12} md={6}>
                    <Box display="flex" flexDirection="row">
                        <Box>
                            <Typography variant="body2">
                                Supplier TIN:
                            </Typography>
                        </Box>
                        <Box pl={1}>
                            <Typography variant="body2">
                                {supplier_tin || ''}
                            </Typography>
                        </Box>
                    </Box>
                    <Box display="flex" flexDirection="row">
                        <Box>
                            <Typography variant="body2">
                                Supplier Registration Number:
                            </Typography>
                        </Box>
                        <Box pl={1}>
                            <Typography variant="body2">
                                {brn || ''}
                            </Typography>
                        </Box>
                    </Box>
                    <Box display="flex" flexDirection="row">
                        <Box>
                            <Typography variant="body2">
                                Supplier SST ID:
                            </Typography>
                        </Box>
                        <Box pl={1}>
                            <Typography variant="body2">
                                {sst_reg_no || ''}
                            </Typography>
                        </Box>
                    </Box>
                    <Box display="flex" flexDirection="row">
                        <Box>
                            <Typography variant="body2">
                                Supplier MSIC code:
                            </Typography>
                        </Box>
                        <Box pl={1}>
                            <Typography variant="body2">
                                {msic_code || ''}
                            </Typography>
                        </Box>
                    </Box>
                </Grid2>
                <Grid2 xs={12} md={6} pt={isMobile ? 2 : 0}>
                    <Box display="flex" flexDirection="row">
                        <Box>
                            <Typography variant="body2">
                                Buyer TIN:
                            </Typography>
                        </Box>
                        <Box pl={1}>
                            <Typography variant="body2" color={"blue"} fontWeight={"bold"}>
                                {buyer_tin || ''}
                            </Typography>
                        </Box>
                    </Box>
                    <Box display="flex" flexDirection="row">
                        <Box>
                            <Typography variant="body2">
                                Buyer Name:
                            </Typography>
                        </Box>
                        <Box pl={1}>
                            <Typography variant="body2" fontWeight={"bold"} color={"blue"}>
                                {buyer_name || ''}
                            </Typography>
                        </Box>
                    </Box>
                    <Box display="flex" flexDirection="row">
                        <Box>
                            <Typography variant="body2">
                                Buyer Registration No:
                            </Typography>
                        </Box>
                        <Box pl={1}>
                            <Typography variant="body2">
                                {buyer?.brn || 'N/A'}
                            </Typography>
                        </Box>
                    </Box>
                    <Box display="flex" flexDirection="row">
                        <Box>
                            <Typography variant="body2">
                                Buyer Address:
                            </Typography>
                        </Box>
                        <Box pl={1}>
                            <Typography variant="body2">
                                {buyer?.address || 'N/A'}
                            </Typography>
                        </Box>
                    </Box>
                    <Box display="flex" flexDirection="row">
                        <Box>
                            <Typography variant="body2">
                                Buyer Contact No:
                            </Typography>
                        </Box>
                        <Box pl={1}>
                            <Typography variant="body2">
                                {buyer_phone || 'N/A'}
                            </Typography>
                        </Box>
                    </Box>
                    <Box display="flex" flexDirection="row">
                        <Box>
                            <Typography variant="body2">
                                Buyer SST Registration No:
                            </Typography>
                        </Box>
                        <Box pl={1}>
                            <Typography variant="body2">
                                {buyer_sst_no || 'N/A'}
                            </Typography>
                        </Box>
                    </Box>
                </Grid2>
            </Grid>
        </Box>
    )
}

export default SupplierBuyer;
