import React, {FC} from "react";
import {Box, Typography} from "@mui/material";

interface IHeaderProps {
    details: any;
}

const Header: FC<IHeaderProps> = (props: IHeaderProps) => {
    const {details} = props;
    if (details === undefined)
        return null;

    const {businessname,supplier_address_line1,
        supplier_address_line2, supplier_postcode,
        supplier_city, supplier_state, supplier_phone,
        supplier_email
    } = details;

    return (
        <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
            <Typography variant="h6" textAlign="center">
                {businessname || ''}
            </Typography>
            <Typography variant="caption" fontSize={13} textAlign="center">
                {`${supplier_address_line1 || ''}, ${supplier_address_line2 || ''}, ${supplier_city || ''}, ${supplier_postcode || ''}, ${supplier_state || ''}`}
            </Typography>
            <Typography variant="caption" fontSize={13}>
                {supplier_phone || ''}
            </Typography>
            <Typography variant="caption" fontSize={13} >
                {supplier_email || ''}
            </Typography>
        </Box>
    )
}

export default Header;
