import { createSlice } from "@reduxjs/toolkit";

export type HomeSliceState = {
    lang: string;
}

export const homeSlice = createSlice({
    name: "home",
    initialState: {
        lang: "en",
    } as HomeSliceState,
    reducers: {
        updateLang: (state: HomeSliceState, action) => { state.lang = action.payload },
    }
});

export const { updateLang } = homeSlice.actions;
export default homeSlice.reducer;
