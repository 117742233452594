import React, {FC} from "react";
import {Box, Typography} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import {getSignatureFromUrl} from "../utils";
import qrcode, { QRCodeSVG } from 'qrcode.react';


interface FooterProps {
    qrcode: string;
    submission_status: string;
}

const Footer: FC<FooterProps> = (props: FooterProps) => {
    const {qrcode, submission_status} = props;

    return (
        <Box
            my={5}
        >
            <Grid2 container spacing={2}>
                <Grid2 xs={12} md={10}>
                    {/*<Typography variant="body2">*/}
                    {/*    Digital Signature:*/}
                    {/*</Typography>*/}
                    {/*<Typography variant="body2">*/}
                    {/*    {getSignatureFromUrl(signature || '')}*/}
                    {/*</Typography>*/}
                    <Typography variant="body2">
                        This document is a visual presentation of the e-invoice
                    </Typography>
                </Grid2>
                {qrcode !== undefined && submission_status === 'Valid' && (
                    <Grid2 xs={12} md={2} display="flex" justifyContent="center" alignItems="center">
                        <QRCodeSVG value={qrcode} size={120} />
                    </Grid2>
                )}
            </Grid2>

        </Box>
    )
}

export default Footer;
