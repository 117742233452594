import React from 'react';
import {AppBar, Box, Container, MenuItem, Select, Toolbar} from "@mui/material";
import {useSelector} from "react-redux";
import {RootState, useAppDispatch} from "../store";
import {updateLang} from "../screens/homeSlice";


const LANGUAGES: { value: string; label: string }[] = [
    { value: 'en', label: 'EN' },
    { value: 'bm', label: 'BM' },
];


const AppBarComponent = () => {
    const lang = useSelector((state: RootState) => state.home).lang;
    const dispatch = useAppDispatch();
    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar component="nav" sx={{ backgroundColor: 'white' }}>
                <Container maxWidth="xl">
                    <Toolbar sx={{display: "flex", justifyContent: "space-between"}}>
                        <Box />
                        <Box>
                            <Select
                                labelId="demo-simple-select-label"
                                id="lang-select"
                                value={lang}
                                sx={{ color: '#000', backgroundColor: '#fff', height: 40 }}
                                onChange={(e) => dispatch(updateLang(e.target.value as string))}
                            >
                                {LANGUAGES.map((i) => {
                                    return (
                                        <MenuItem key={i.value} value={i?.value || 'en'}>
                                            {i?.label || ''}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
        </Box>
    )
}

export default AppBarComponent;
